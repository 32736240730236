
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import 'eco-design-micro/css/devices.scss';

.HeaderV2-user-win-pc {
    position: absolute;
    top: 24px;
    left: auto;
    right: -24px;
    z-index: 3;
    margin: 0;
    padding: 12px 24px;
    border: none;
    outline: none;
    opacity: 1;
    background-color: transparent;
    display: none;
    
    @include devices(pad) {
        display: none !important;
    }
    .HeaderV2-UserTipsWin-content {
        position: relative;
        width: 250px;
        padding: 26px 14px 18px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, .08), 0 3px 6px -4px rgba(0, 0, 0, .12), 0 9px 28px 8px rgba(0, 0, 0, .05);
        text-align: center;
    }
}

// 用于悬浮
.HeaderV2-r-item-open1 {
    .HeaderV2-user-win-pc {
        display: block;
    }
    
}
// 用于点击
.HeaderV2-r-item-open2 {
    .HeaderV2-user-win-pc {
        display: block !important;
        .HeaderV2-UserTipsWin-close {
            display: block;
        }
    }
    @include devices(pad) {
        display: none !important;
    }
}


// 共用内容区
.HeaderV2-UserTipsWin-content {
    display: flex;
    flex-direction: column;
    .HeaderV2-UserTipsWin-line1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        color: #000;
        font-family: var(--ff-woff2-EM);
        font-size: 12px;
        line-height: 1.2;
        text-align: left;
    }
    .HeaderV2-UserTipsWin-line2 {
        width: 100%;
        margin: 12px auto 10px;
        padding: 10px;
        line-height: 18px;
        border-radius: 2px;
        background: #000;
        color: #fff;
        font-family: var(--ff-woff2-EM);
        font-size: 12px;
        cursor: pointer;
    }
    .HeaderV2-UserTipsWin-line3 {
        line-height: 18px;
        text-align: center;
        font-family: var(--ff-woff2-EM);
        color: #707070;
        font-size: 12px;
        white-space: pre-wrap;
    
        
    }
    .HeaderV2-UserTipsWin-line3-link {
        text-decoration: underline;
        color: #000;
    }

    .HeaderV2-UserTipsWin-close {
        position: absolute;
        top: 8px;
        right: 8px;
        display: none;
    }
}





.HeaderV2-user-win-h5 {
    display: none;
    @include devices(pad) {
        display: block;
    }
    .HeaderV2-UserTipsWin-content {
        display: grid;
        grid-template-columns: 1fr 90px;
        padding: 12px;
        background-color: #fff;
    }
    .HeaderV2-UserTipsWin-line2 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 4;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .HeaderV2-UserTipsWin-line1 {
        font-family: var(--ff-woff2-M);
        justify-content: flex-start;
    }
    .HeaderV2-UserTipsWin-line3 {
        font-family: var(--ff-woff2-M);
        text-align: left;
    }
}