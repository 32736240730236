@import 'eco-design-micro/css/devices.scss';


// 子级列表
.HeaderV2-NavType2-sub-h5 {
    display: none;
    justify-content: flex-start;
    gap: 12px;
    overflow-x: auto;
    @include devices(pad) {
        padding: 0 48px;
    }
    @include devices(h5) {
        padding: 0 24px;
    }
}
.HeaderV2-NavType2-sub-h5::-webkit-scrollbar {
    display: none;
}
.HeaderV2-NavType2-sub-h5-item {
    position: relative;
    width: 308px;
    min-width: 308px;
}
.HeaderV2-NavType2-sub-h5-link {
    display: flex;
    position: relative;
    height: 200px;
}

.HeaderV2-NavType2-sub-h5-msg {
    position: absolute;
    left: 0;
    bottom: 16px;
    width: 100%;
}
