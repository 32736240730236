
// 最外层布局

.HeaderV2-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    height: var(--HeaderV2-h);
    @include devices(desktop) {
        margin: 0 120px;
    }
    @include devices(pad) {
        margin: 0 48px;
    }
    @include devices(h5) {
        margin: 0 24px;
    }
}
