
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import 'eco-design-micro/css/devices.scss';
@import './css/layout1.scss';
@import './HeaderNav/index.scss';
@import './HeaderNav/NavType1/index.scss';
@import './HeaderNav/NavType2/index.scss';
@import './HeaderNav/NavType3/index.scss';
@import './HeaderNav/NavType4/index.scss';
@import './HeaderNav/NavSceneA/index.scss';
.HeaderV2 {
    position: relative;
    z-index: 29;
    border-bottom: 1px solid #F5F5F5;
    --HeaderV2-h: 51px;
    --HeaderV2-color-gray: #F4F4F4;
    --HeaderV2-color-gray-hover: rgba(244, 244, 244, 0.50);
    --HeaderV2-sub-child-padding1: 0 48px 0 58px;
    --HeaderV2-sub-child-padding2: 0 48px 0 68px;
    background-color: #fff;
    @include devices(h5) {
        --HeaderV2-sub-child-padding1: 0 24px 0 34px;
        --HeaderV2-sub-child-padding2: 0 24px 0 44px;
    }
}

// 其它
.HeaderV2-l {
    display: flex;
    align-items: center;
    width: 117px;
}

// 右侧
.HeaderV2-r {
    display: flex;
    align-items: center;
    margin-left: 16px;
    gap: 20px;
    .HeaderV2-r-item {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .HeaderV2-r-item-store {
        position: relative;
    }
    @include devices(pad) {
        margin-left: auto;
    }
}

// search
.HeaderV2-search {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 1200px;
    transform: translateX(-50%);
    display: none;
    @include devices(pad) {
        .dropdown-inter {
            margin-left: 0;
            margin-right: 0;
        }
        .searchbox-c-item {
            text-align: left;
        }
    }
}

// store
.HeaderV2-r-item-store {
    .HeaderV2-r-item-store-btn {
        display: flex;
        align-items: center;
        height: 32px;
        padding: 0 10px;
        border-radius: 100px;
        background-color: #3F68E0;
        color: #fff;
        cursor: pointer;
        font-family: var(--ff-woff2-M);
        font-size: 12px;
    }
    .HeaderV2-r-item-store-btn:hover {
        opacity: 0.8;
    }
    .HeaderV2-r-item-store-icon {
        display: none;
    }
    @include devices(pad) {
        .HeaderV2-r-item-store-btn {
            display: none;
        }
        .HeaderV2-r-item-store-icon {
            display: flex;
        }
    }
}

.HeaderV2-r-item-store:hover {
    html[site='cn'] & {
        .storeBox_pc {
            display: block;
        }
    }
}
.HeaderV2-search-open {
    .HeaderV2-nav, .HeaderV2-r {
        visibility: hidden !important;
    }
    .HeaderV2-search {
        display: block;
    }
}