
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import '@elem/styles/devices.scss';

.ccpa-modal {
    :global {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        .modal-box {
            width: 480px;
            border-radius: 4px;
            // font-family: var(--ff-woff2-EB);
            color: #000;
            background-color: #fff;
            padding: 24px;
            position: relative;
            .header-close {
                width: 24px;
                height: 24px;
                font-size: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-left: auto;
            }
            .modal-content {
                margin-top: 12px;
                line-height: 1.2;
                text-align: center;
                font-size: 16px;
                white-space: pre-line;
            }
            .modal-btn{
                margin-top: 20px;
                display: flex;
                margin-left: auto;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 32px;
                background-color: #3F68E0;
                color: #fff;
                cursor: pointer;
                border-radius: 2px;
                font-size: 14px;
                border: none;
            }
           .modal-submit-error{
                color: #F00;
                font-size: 14px;
                position: absolute;
                bottom: 30px;
                left: 24px;
                width: calc(100% - 120px - 48px - 12px);
                text-align: right;
            }
             
        }

       
    }
}
