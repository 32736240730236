@import 'eco-design-micro/css/devices.scss';

.HeaderV2-nav {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 16px;
    @include devices(pad) {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        background-color: #fff;
        display: none;
        overflow-y: auto;
        height: calc(100vh - 52px);
        padding-bottom: 48px;
    }
    @include devices(h5) {
    }
}
.HeaderV2-nav-item-list-h {
    position: absolute;
    top: 16px;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    display: flex;
    justify-content: flex-end;
    max-width: 1200px;
    height: 24px;
    margin: 0 auto;
    .HeaderV2-nav-item-list-close {
        font-size: 24px;
        cursor: pointer;
    }
    @include devices(desktop) {
        max-width: 100%;
        padding: 0 120px;
    }
    @include devices(pad) {
        max-width: 100%;
        padding: 0 48px;
        display: none !important;
    }
    @include devices(h5) {
        padding: 0 24px;
    }
}
// 手机端时打开导航
.HeaderV2:has(.HeaderToggleNav.HeaderToggleNav-open) {
    @include devices(pad) {
        .HeaderV2-nav {
            display: block;
        }
    }
}
// 手机端打开导航时锁住滚动
body:has(.HeaderToggleNav.HeaderToggleNav-open) {
    @include devices(pad) {
        overflow: hidden;
    }
}
.HeaderV2-nav-item-button {
    position: relative;
    display: flex;
    gap: 4px;
    align-items: center;
    width: 100%;
    height: var(--HeaderV2-h);
    font-family: var(--ff-woff2-B);
    color: #000;
    cursor: pointer;
    @include devices(pad) {
        justify-content: space-between;
        padding: 0 48px;
        height: 60px;
        font-size: 16px;
    }
    @include devices(h5) {
        padding: 20px 24px;
    }
}
.HeaderV2-nav-item-button::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #000;
    transition: width 0.3s;
    @include devices(pad) {
        display: none !important;
    }
}


.HeaderV2-nav-item-icon {
    display: flex;
    justify-content: center;
    width: 13px;
    height: 13px;
    background-image: url('https://websiteoss.ecoflow.com/upload/image/1742204933531/arr.svg');
    transition: transform 0.3s;
}

.HeaderV2-nav-item-list {
    display: none;
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    background-color: #fff;
    
    @include devices(pad) {
        position: static;
        padding: 0;
    }
}
.HeaderV2-nav-item-list-content {
    overflow-y: auto;
    max-height: calc(100vh - 100px);
    padding-top: calc(16px + 24px);
    padding-bottom: 48px;
    @include devices(pad) {
        padding-top: 0;
        padding-bottom: 0;
        overflow-y: inherit;
        max-height: none;
    }
}

.HeaderV2-nav-item-open {
    .HeaderV2-nav-item-list {
        display: block;
        
    }
    .HeaderV2-nav-item-button .HeaderV2-nav-item-icon {
        transform: rotate(180deg);
    }
    .HeaderV2-nav-item-button::after {
        width: 100%;
    }
}

.HeaderV2-nav-item-list::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
    pointer-events: none;
}