@import 'eco-design-micro/css/devices.scss';
@import './css/h5.scss';
@import './css/pc.scss';

.HeaderV2-NavType2 {
    display: flex;
    justify-content: center;
    gap: 60px;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    --HeaderV2-NavType2-w-l: 282px;
    .HeaderV2-NavType2-l {
        position: static;
        width: var(--HeaderV2-NavType2-w-l);
        min-width: var(--HeaderV2-NavType2-w-l);
    }
    .HeaderV2-NavType2-r {
        flex: 1;
    }
    @include devices(desktop) {
        margin: 0 120px;
    }
    @include devices(pad) {
        flex-direction: column;
        background-color: #f4f4f4;
        margin: 0;
        gap: 0;
        --HeaderV2-NavType2-w-l: 100%;
        .HeaderV2-NavType2-l {
            padding-bottom: 24px;
        }
        .HeaderV2-NavType2-r {
            display: none !important;
        }
        .HeaderV2-NavType2-sub-h5 {
            display: flex;
        }
    }
    @include devices(h5) {
    }
}
.HeaderV2-NavType2-item {
    position: static;
    cursor: pointer;
}
.HeaderV2-NavType2-item-title {
    font-family: var(--ff-woff2-B);
    font-size: 14px;
    color: #000;
    padding: 0 20px;
    height: 48px;
    display: flex;
    align-items: center;
    @include devices(pad) {
        padding: 0 48px;
    }
    @include devices(h5) {
        padding: 0 24px;
    }
}

// 打开子级
.HeaderV2-NavType2-item.HeaderV2-NavType2-item-open {
    .HeaderV2-NavType2-item-title {
        background-color: #f4f4f4;
    }
    @include devices(pad) {
    }
}

// 卡片样式
.HeaderV2-nav {
    .HeaderV2-NavType2-sub-pc-link {
        display: flex;
        flex-direction: column;
        height: 200px;
    }
    .HeaderV2-NavType2-sub-pc-img {
        border-radius: 4px;
        overflow: hidden;
    }
    .HeaderV2-NavType2-sub-h5-msg, .HeaderV2-NavType2-sub-pc-msg {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 12px 12px;
        color: #fff;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .HeaderV2-NavType2-sub-h5-title, .HeaderV2-NavType2-sub-pc-title {
        font-family: var(--ff-woff2-B);
        font-size: 14px;
    }
    .HeaderV2-NavType2-sub-h5-desc, .HeaderV2-NavType2-sub-pc-desc {
        font-family: var(--ff-woff2-M);
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}