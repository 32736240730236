@import 'eco-design-micro/css/devices.scss';

// 非场景-无主图
.HeaderV2-NavSceneA-h5 .HeaderV2-NavSceneA-item1.HeaderV2-NavSceneA-item1-scene-n {
    display: none;
    @include devices(pad) {
        display: flex;
        .HeaderV2-NavSceneA-item1-c {
            grid-template-columns: 1fr !important;
            padding: var(--HeaderV2-sub-child-padding2);
            gap: 16px;
        }
        .HeaderV2-NavSceneA-item2 {
            display: flex;
            align-items: center;
            gap: 4px;
        }
        .HeaderV2-NavSceneA-item2-img {
            width: 80px;
            height: 80px;
            margin: 0;
        }
        .HeaderV2-NavSceneA-item2-msg {
            flex: 1;
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
        .HeaderV2-NavSceneA-item2-label {
            justify-content: flex-start;
        }
        .HeaderV2-NavSceneA-item1-h {
            height: 49px;
            padding: var(--HeaderV2-sub-child-padding2);
        }
    }
}
// 非场景-有主图且列表第一个
.HeaderV2-NavSceneA-h5 .HeaderV2-NavSceneA-item1-main-y {
    .HeaderV2-NavSceneA-item2-type-main {
        order: 1;
        background-color: #fff;
        padding: 12px;
        .HeaderV2-NavSceneA-item2-img {
            width: 120px;
            height: 120px;
        }
    }
}
// 场景图
.HeaderV2-NavSceneA-h5 .HeaderV2-NavSceneA-item1.HeaderV2-NavSceneA-item1-scene-y {
    display: none;
    @include devices(pad) {
        display: flex;
        .HeaderV2-NavSceneA-item1-h {
            padding: var(--HeaderV2-sub-child-padding2);
        }
        .HeaderV2-NavSceneA-item1-c {
            overflow-x: auto;
            display: flex;
            padding: var(--HeaderV2-sub-child-padding2);
        }
        .HeaderV2-NavSceneA-item1-c::-webkit-scrollbar {
            display: none;
        }
        .HeaderV2-NavSceneA-item2 {
            position: relative;
            width: 280px;
            height: 173px;
            flex: none;
            .HeaderV2-NavSceneA-item2-img {
                max-width: 100%;
                margin: 0 auto;
                border-radius: 4px;
                overflow: hidden;
                width: 100%;
            }
            .eco-img-item {
                background-size: cover;
            }    
            .HeaderV2-NavSceneA-item2-msg {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                text-align: left;
                padding: 12px;
            }
            .HeaderV2-NavSceneA-item2-title {
                color: #fff;
            }
            .HeaderV2-NavSceneA-item2-desc {
                color: #fff;
            }
        }
    }
    @include devices(h5) {
    }
}

.HeaderV2-NavType1-sub1-item.HeaderV2-NavType1-sub1-item-open {
    @include devices(pad) {
        .HeaderV2-NavSceneA.HeaderV2-NavSceneA-h5 {
            display: block;
            padding-bottom: 16px;
        }
        .HeaderV2-nav-item-icon {
            transform: rotate(180deg);
        }
        .HeaderV2-NavSceneA.HeaderV2-NavSceneA-h5:has(.HeaderV2-NavSceneA-item1-f) {
            padding-bottom: 0;
        }
    }
}