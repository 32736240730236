@import 'eco-design-micro/css/devices.scss';

.HeaderV2-NavSceneA.HeaderV2-NavSceneA-pc {
    display: flex;
}
.HeaderV2-NavSceneA-pc {
    
    .HeaderV2-NavSceneA-item2-msg {
        padding: 0 12px 12px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
    .HeaderV2-NavSceneA-item2-label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14px;
        margin-bottom: 2px;
    }
    
}

// 非场景图
.HeaderV2-NavSceneA-pc .HeaderV2-NavSceneA-item1.HeaderV2-NavSceneA-item1-scene-n {
    .HeaderV2-NavSceneA-item2-img {
        max-width: 110px;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
        @supports (aspect-ratio: 1 / 1) {
            aspect-ratio: 1;
            height: auto;
        }
        @supports not (aspect-ratio: 1 / 1) {
            height: 110px;
        }
        
    }
    .HeaderV2-NavSceneA-item2:hover {
        background-color: var(--HeaderV2-color-gray-hover);
    }
}

// 非场景-有主图且第一个 HeaderV2-NavSceneA-item1-main-y
.HeaderV2-NavSceneA-pc .HeaderV2-NavSceneA-item1-main-y {
    .HeaderV2-NavSceneA-item1-c {
        display: grid;
        grid-template-columns: 160fr 160fr 166fr 166fr 166fr;
        gap: 10px;
    }
    @include devices(desktop) {
        .HeaderV2-NavSceneA-item1-c {
            grid-template-columns: 114.5fr 114.5fr 154fr 154fr 154fr;
        }
    }
    .HeaderV2-NavSceneA-item2 {
        background-color: var(--HeaderV2-color-gray);
        display: flex;
        flex-direction: column;
        order: 1;
    }
    .HeaderV2-NavSceneA-item2-type-main {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        .HeaderV2-NavSceneA-item2-img {
            max-width: 250px;
            @supports not (aspect-ratio: 1 / 1) {
                height: 250px;
            }
        }
        .HeaderV2-NavSceneA-item2-msg {
            padding: 0 24px 24px;
            margin-top: auto;
        }
        .HeaderV2-NavSceneA-item2-title {
            -webkit-line-clamp: 3;
            font-size: 20px;
        }
        .HeaderV2-NavSceneA-item2-desc {
            font-size: 16px;
        }
    }
}


// 场景图
.HeaderV2-NavSceneA-pc .HeaderV2-NavSceneA-item1.HeaderV2-NavSceneA-item1-scene-y {
    .HeaderV2-NavSceneA-item1-c {
        grid-template-columns: 1fr 1fr 1fr;
    }
    // 场景图
    .HeaderV2-NavSceneA-item2 {
        position: relative;
        height: 173px;
        .HeaderV2-NavSceneA-item2-img {
            aspect-ratio: 1;
            border-radius: 4px;
            overflow: hidden;
        }
        .eco-img-item {
            background-size: cover;
            transition: transform 0.4s;
        }    
        .HeaderV2-NavSceneA-item2-msg {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: left;
        }
        .HeaderV2-NavSceneA-item2-title {
            color: #fff;
        }
        .HeaderV2-NavSceneA-item2-desc {
            color: #fff;
        }
    }
    .HeaderV2-NavSceneA-item2:hover {
        .HeaderV2-NavSceneA-item2-img .eco-img-item-pc {
            transform: scale(1.2);
            transform-origin: center center;
        }
    }
}
