
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import 'lowcode/common/css/devices.scss';


.ef-check-in-dialog-desc {
    margin-top: 32px;
    font-family: var(--ff-woff2-M);
    font-size: 16px;
    text-align: center;
}