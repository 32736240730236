
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import 'lowcode/common/css/devices.scss';

.ef-progress-circle {
    position: relative;
    width: 100%;
	height: 100%;
	background: var(--ef-progress-circle-background);
	border-radius: 50%;
	position: relative;
	&::before {
		content: attr(data-progress);
		position: absolute;
		inset: 3px;
		background-color: var(--ef-progress-circle-transparentColor);
		width: calc(100% - 6px);
		height: calc(100% - 6px);
		border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--ef-progress-circle-nowCircleColor);
        font-size: 18px;
	}
}