
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import '@elem/styles/devices.scss';

.HeaderToggleNav {
    position: relative;
    display: none;
    width: 24px;
    height: 24px;
    .HeaderToggleNav-item {
        position: relative;
        display: block;
        width: 17px;
        height: 8px;
        margin: 0 auto;
        transition: transform 0.5s;
        opacity: 0;
        &:after {
            content: '';
            position: absolute;
            top: 3px;
            left: 0;
            width: 17px;
            height: 2px;
            background-color: #000;
        }
    }
    .HeaderToggleNav-bg {
        position: absolute;
        top: 0;
        left: 0;
    }
    @include devices(pad) {
        display: block;
    }
}

.HeaderToggleNav.HeaderToggleNav-open {
    .HeaderToggleNav-bg {
        display: none;
    }
    .HeaderToggleNav-item1 {
        opacity: 1;
        transform: translate(0px, 8px) rotate(45deg);
    }
    .HeaderToggleNav-item2 {
        opacity: 0;
    }
    .HeaderToggleNav-item3 {
        opacity: 1;
        transform: translate(0px, -8px) rotate(-45deg);
    }
}