
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import '@/styles/variable.scss';
@import '@elem/styles/devices.scss';

.footer {
    :global {
        position: relative;
        width: 100%;
        padding: 0 128px;
        .fa-item-lang-h5 {
            display: none;
        }
        .fa-item-lang-pc {
            display: block;
        }
        .footer-a {
            padding-top: 60px;
            .fa-item {
                .fa-list {
                    > a {
                        &:hover,
                        &:active {
                            color: #343434;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .footer-b {
            .icon-list {
                .icon {
                    color: #1f1f1f;
                }
            }
        }
        .left-ccpa{
            border-left: 1px solid #626262;
            padding-left: 24px;
            margin-left: 24px;
            display: inline;
            cursor: pointer;
        }
        @include devices(pc) {
            padding: 0 calc(50vw - 600px);
            .footer-a {
                padding-top: 80px;
            }
        }
        @include devices(pc1024equal) {
            background-color: #f4f4f4;
            border-top: 1px solid #e0e0e0;
            .footer-a {
                display: flex;
                justify-content: space-between;
                h3 {
                    font-family: var(--ff-woff2-B);
                    font-size: 18px;
                    line-height: 22px;
                    color: #343434;
                }
                .fa-list {
                    padding-top: 12px;
                    & > a {
                        display: block;
                        line-height: 17px;
                        padding-right: 10px;
                        margin-top: 12px;
                        color: #626262;
                        font-size: 14px;
                        text-decoration: none;
                        word-break: break-all;
                        cursor: pointer;
                        font-family: var(--ff-woff2-M);
                    }
                }
                .fa-itemb {
                    display: none;
                }
            }
            .footer-b {
                display: flex;
                justify-content: space-between;
                margin: 0 auto 0;
                padding: 60px 0 32px;
                font-family: var(--ff-woff2-M);
                .icon-list {
                    html[site='cn'] & {
                        display: flex;
                        justify-content: space-between;
                    }
                    html[site='jp'] & {
                        display: flex;
                        align-items: baseline;
                    }
                    html[site='sa'] & {
                        .icon:first-child {
                            margin-right: 0;
                        }
                    }
                    .icon {
                        display: inline-block;
                        position: relative;
                        width: 28px;
                        height: 28px;
                        font-size: 28px;
                        margin-right: 20px;
                        color: #1f1f1f;
                        vertical-align: top;
                        cursor: pointer;
                        .img-wrapper {
                            position: relative;
                            display: block;
                            width: 28px;
                            height: 28px;
                        }
                        &:hover {
                            color: #2673ff;
                            .hover-qrcode {
                                display: block;
                            }
                        }
                        .hover-qrcode {
                            position: absolute;
                            background: #fff;
                            left: -36px;
                            top: -105px;
                            display: none;
                            width: 100px;
                            height: 100px;
                            border: 1px solid #eee;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                        }
                        .hover-qrcode::after {
                            content: '';
                            width: 10px;
                            height: 10px;
                            background: #fff;
                            border-bottom: 1px solid #eee;
                            border-right: 1px solid #eee;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                            position: absolute;
                            bottom: -5px;
                            left: calc(50% - 5px);
                            transform: rotate(45deg);
                        }
                    }
                }
                .right {
                    .sub {
                        html[site='sa'] & {
                            .form-email-box {
                                direction: ltr;
                                input {
                                    text-align: right;
                                    padding-left: 0;
                                    padding-right: 15px;
                                }
                            }
                        }
                        position: relative;
                        .input-msg,
                        .success-msg,
                        .input-msg-success {
                            position: absolute;
                            top: -2em;
                            right: 0;
                            color: #f03e3e;
                            text-align: right;
                            white-space: nowrap;
                            font-size: 14px;
                        }
                        .success-msg.success {
                            top: -1em;
                            &.line-h-3 {
                                top: -3em;
                            }
                        }
                        .input-msg-success {
                            color: #919191;
                        }
                        .input-msg-au {
                            position: absolute;
                            top: -1em;
                            right: 0;
                            color: #2673ff;
                            text-align: right;
                            font-size: 14px;
                            white-space: pre-line;
                            width: 500px;
                            margin-top: -12px;
                        }
                        .emails-protocol {
                            color: #919191;
                            margin-top: 10px;
                            display: flex;
                            input[type='checkbox'] {
                                width: 16px;
                                height: 16px;
                                margin-right: 5px;
                                html[site='sa'] & {
                                    margin-right: 0;
                                    margin-left: 5px;
                                }
                            }
                            label {
                                line-height: 18px;
                                max-width: 360px;
                            }
                        }
                        .input-email {
                            display: inline-block;
                            font-size: 14px;
                            color: var(--navbg);
                            height: 48px;
                            width: 352px;
                            background: 0 0;
                            border: 1px solid #ddd;
                            html[site='jp'] & {
                                &::input-placeholder {
                                    font-family: var(--ff-woff2-M);
                                }
                                &::-webkit-input-placeholder {
                                    font-family: var(--ff-woff2-M);
                                }
                            }
                            html[site='es'] & {
                                width: 240px;
                            }
                            &:hover {
                                border: 1px solid #121212;
                            }
                            outline: none;
                            padding: 0px 0px 0px 16px;
                            border-right: none;
                            vertical-align: middle;
                            border-radius: 8px 0 0 8px;
                        }
                        .error-color {
                            border-color: #f03e3e;
                            &:hover {
                                border-color: #f03e3e;
                            }
                        }
                        .form-submit {
                            display: inline-block;
                            width: 48px;
                            height: 48px;
                            margin-left: -4px;
                            font-size: 12px;
                            line-height: 1;
                            color: #fff;
                            background: var(--navbg);
                            border: none;
                            outline: none;
                            vertical-align: middle;
                            border-radius: 0 8px 8px 0;
                            cursor: pointer;
                            img {
                                width: 30px;
                                height: 24px;
                                vertical-align: middle;
                            }
                            .form-email {
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                font-size: 24px;
                            }
                        }
                    }
                    .sms-form {
                        .sms-form-item {
                            display: inline-block;
                            text-align: right;
                        }
                        .sms-container-div {
                            border: 1px solid #e0e0e0;
                            display: inline-block;
                            border-radius: 4px;
                            overflow: hidden;
                        }
                        .sms-container {
                            height: 42px;
                            display: flex;
                            .phone-input {
                                flex: 1;
                                height: 42px;
                                border: none;
                                background: #fff;
                                &:focus {
                                    border: none;
                                    box-shadow: none;
                                }
                            }
                            .area-select {
                                width: 100px;
                                .ant-select-selector {
                                    height: 42px;
                                    border: none;
                                    background: #f4f4f4;
                                    .ant-select-selection-item {
                                        line-height: 42px;
                                    }
                                }
                                .ant-select-selection-placeholder {
                                    line-height: 42px;
                                }
                                &.ant-select-focused .ant-select-selector {
                                    box-shadow: 0 0 0 0px;
                                }
                                .ant-select-selection-search-input {
                                    height: 42px;
                                    line-height: 42px;
                                }
                            }
                            .sms-btn {
                                width: 48px;
                                height: 42px;
                                border-radius: 0;
                                border: none;
                                line-height: 46px;
                                background: #1f1f1f;
                                &:active {
                                    color: #000;
                                    border: none;
                                }
                                &:hover {
                                    color: #000;
                                }
                            }
                        }
                        .input-msg,
                        .success-msg {
                            position: static;
                            margin-top: 8px;
                        }
                    }
                }
            }
            .footer-c {
                line-height: 1.57;
                font-size: 14px;
                color: #626262;
                font-family: var(--ff-woff2-M);
                html[site='sa'] & {
                    .lang_item_current {
                        margin-left: 0;
                        margin-right: 20px;
                        svg {
                            margin-left: 6px;
                        }
                    }
                }
                .footer-c-in {
                    position: relative;
                    padding: 32px 0 80px;
                    display: flex;
                    justify-content: space-between;
                    max-width: 1248px;
                    margin: 0 auto;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background-color: #e0e0e0;
                    }
                    .left {
                        white-space: pre-line;
                        font-family: var(--ff-woff2-M);
                    }
                }
                .footer-c-border {
                    width: 100%;
                    height: 1px;
                    background-color: #d2d2d7;
                }
                .left {
                    a {
                        color: #626262;
                    }
                    .left-ccpa{
                        border-left: 1px solid #626262;
                        padding-left: 24px;
                        margin-left: 24px;
                        display: inline;
                    }
                }
                .right {
                    display: flex;
                    font-size: 14px;
                    line-height: 22px;
                    font-family: var(--ff-woff2-M);
                    html[site='jp'] & {
                        font-weight: 400;
                    }
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                }
            }
        }
        @include devices(pad) {
            padding: 0;
            background-color: #f4f4f4;
            border-top: 1px solid #e0e0e0;
            .fa-item-lang-h5 {
                display: block;
            }
            .fa-item-lang-pc {
                display: none;
            }
            .footer-a {
                display: block;
                padding-top: 0;
                .fa-item {
                    margin: 0 24px;
                    border-bottom: 1px solid #e0e0e0;
                }
                h3 {
                    position: relative;
                    font-size: 14px;
                    line-height: 48px;
                    color: #03060b;
                    font-family: var(--ff-woff2-B);
                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 10px;
                        height: 1.5px;
                        margin-top: -0.75px;
                        font-size: 0;
                        background-color: #343434;
                        transform: rotate(0deg);
                        html[site='sa'] & {
                            left: 0;
                            right: unset;
                        }
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 10px;
                        height: 1.5px;
                        margin-top: -0.75px;
                        font-size: 0;
                        background-color: #343434;
                        transform: rotate(90deg);
                        html[site='sa'] & {
                            left: 0;
                            right: unset;
                        }
                    }
                }
                .fa-list {
                    display: none;
                    padding: 0 16px 16px;
                    font-family: var(--ff-woff2-M);
                    font-weight: 300;
                    & > a {
                        display: block;
                        line-height: 36px;
                        font-size: 14px;
                        color: #444;
                        cursor: pointer;
                    }
                }
                .fa-item.opend {
                    h3 {
                        &::before {
                            transform: rotate(45deg);
                        }
                        &:after {
                            transform: rotate(-45deg);
                        }
                    }
                    .fa-list {
                        display: block;
                    }
                }
                .fa-itemb {
                    position: relative;
                    margin: 0 24px;
                    font-size: 14px;
                    padding: 16px 0;
                    color: #343434;
                    border-bottom: 1px solid #e0e0e0;
                    .fa-itemb-icon {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        font-size: 14px;
                        color: #343434;
                        font-size: 12px;
                        transform: translate(0, -50%) rotate(-90deg);
                        html[site='sa'] & {
                            left: 0;
                            right: unset;
                            transform: rotate(90deg);
                        }
                    }
                    .fa-itemb-desc {
                        font-size: 12px;
                        line-height: 12px;
                        color: #949494;
                        font-family: var(--ff-woff2-M);
                        letter-spacing: -0.2px;

                        html[site='jp'] & {
                            margin-top: 3px;
                        }
                    }
                    .fa-itemb-title {
                        margin-top: 2px;
                        font-family: var(--ff-woff2-M);
                    }
                    .lang_item_current {
                        width: 100%;
                        line-height: 19px;
                        margin-left: 0;
                        .lang_text {
                            font-size: 14px;
                            html[site='sa'] & {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
            .footer-b {
                display: flex;
                flex-wrap: wrap;
                padding: 48px 24px 0;
                .icon-list {
                    width: 100%;
                    order: 2;
                    padding-top: 32px;
                    text-align: center;

                    .icon {
                        display: inline-block;
                        position: relative;
                        width: 28px;
                        height: 28px;
                        font-size: 28px;
                        margin: 0 10px;
                        color: #1f1f1f;
                        cursor: pointer;

                        .img-wrapper {
                            position: relative;
                            display: block;
                            transition: all 0.3s;
                        }
                        .hover-qrcode {
                            display: none;
                        }
                    }
                }

                .right {
                    width: 100%;
                    order: 1;
                    .sub {
                        position: relative;
                        .input-msg,
                        .success-msg {
                            color: #f03e3e;
                            text-align: right;
                            font-size: 14px;
                            text-align: center;
                            margin-top: 4px;
                            white-space: pre-line;
                        }
                        .input-msg-au {
                            color: #2673ff;
                            text-align: right;
                            font-size: 14px;
                            text-align: center;
                            margin-top: 4px;
                        }
                        .form-email-box {
                            position: relative;
                            max-width: 327px;
                            margin: 0 auto;
                            padding-right: 48px;
                            white-space: nowrap;
                            letter-spacing: -4px;
                        }
                        .emails-protocol {
                            color: #919191;
                            display: flex;
                            max-width: 380px;
                            margin: 10px auto 0;
                            input[type='checkbox'] {
                                width: 16px;
                                height: 16px;
                                margin-right: 5px;
                            }
                            label {
                                line-height: 18px;
                            }
                        }
                        .input-email {
                            border: 1px solid #e0e0e0;
                            font-family: var(--ff-woff2-M);
                            display: inline-block;
                            vertical-align: top;
                            letter-spacing: 0;
                            font-size: 12px;
                            color: var(--navbg);
                            height: 44px;
                            width: 100%;
                            background: #fff;
                            &:hover {
                                border-color: #121212;
                            }
                            &:hover + button {
                                background: #121212;
                            }
                            outline: none;
                            padding: 0px 0px 0px 16px;
                            border-right: none;
                            vertical-align: middle;
                            border-radius: 8px 0 0 8px;
                            html[site='sa'] & {
                                padding-right: 0.15rem;
                            }
                        }
                        .form-submit {
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 48px;
                            height: 44px;
                            font-size: 24px;
                            color: #fff;
                            background: #1f1f1f;
                            border: none;
                            outline: none;
                            vertical-align: middle;
                            border-radius: 0 8px 8px 0;
                            cursor: pointer;
                            text-align: center;
                            .footer-search-icon {
                                display: inline-block;
                                vertical-align: top;
                                color: #fff;
                                margin-top: 0;
                                font-size: 0.3rem;
                            }
                        }
                    }
                    .sms-form {
                        .sms-form-item {
                            display: flex;

                            flex-direction: column;
                        }
                        .sms-container-div {
                            border: 1px solid #e0e0e0;
                            display: inline-block;
                            border-radius: 4px;
                            overflow: hidden;
                            margin: 0 auto;
                        }
                        .sms-container {
                            height: 42px;
                            display: flex;

                            .phone-input {
                                flex: 1;
                                height: 42px;
                                border: none;
                                background: #fff;
                                &:focus {
                                    border: none;
                                    box-shadow: none;
                                }
                            }
                            .area-select {
                                width: 100px;
                                .ant-select-selector {
                                    height: 42px;
                                    border: none;
                                    background: #f4f4f4;
                                    .ant-select-selection-item {
                                        line-height: 42px;
                                    }
                                    .ant-select-selection-placeholder {
                                        line-height: 42px;
                                    }
                                }
                                &.ant-select-focused .ant-select-selector {
                                    box-shadow: 0 0 0 0px;
                                }
                                .ant-select-selection-search-input {
                                    height: 42px;
                                    line-height: 42px;
                                }
                            }
                            .sms-btn {
                                width: 48px;
                                height: 42px;
                                border-radius: 0;
                                border: none;
                                line-height: 46px;
                                background: #1f1f1f;
                                svg {
                                    color: #fff;
                                }
                                &:active {
                                    color: #000;
                                    border: none;
                                }
                                &:hover {
                                    color: #000;
                                }
                            }
                        }
                    }
                }
            }
            .footer-c {
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #949494;
                padding: 12px 24px 32px;
                .left {
                    white-space: pre-line;
                }
                .language-outbox {
                    .lang_item_current {
                        margin: 0 auto;
                        justify-content: center;
                    }
                }
            }
        }
        @include devices(h5){
            .left-ccpa{
                border-left: 0 solid transparent;
                padding-left: 0;
                margin-left: 0;
               padding-top: 12px;
               display: block;
            }
        }
    }
    &:global(.black),
    &:global(.gray) {
        :global {
            @include devices(pc1024) {
                border-top: 1px solid #343434;
                background-color: #1f1f1f;
                --svg-efearth-color: #919191;
                .footer-a {
                    h3 {
                        color: #fff;
                    }
                    .fa-item {
                        .fa-list > a {
                            color: #afafaf !important;
                        }
                    }
                }
                .footer-b {
                    .icon-list {
                        .icon {
                            color: #e6e6e6;
                            &:hover,
                            &:active {
                                color: #fff;
                            }
                        }
                    }
                    .sms-form {
                        .sms-container {
                            .phone-input {
                                background: #000;
                                color: #fff;
                                &::placeholder {
                                    color: #808080;
                                }
                            }
                            .area-select {
                                width: 100px;
                                .ant-select-selector {
                                    background: #222;
                                    color: #fff;
                                    .ant-select-selection-item {
                                        line-height: 42px;
                                    }
                                    .ant-select-selection-placeholder {
                                        line-height: 42px;
                                    }
                                }
                            }
                            .sms-btn {
                                width: 48px;
                                height: 42px;
                                border-radius: 0;
                                border: none;
                                line-height: 46px;
                                background: #fff;
                                &:active {
                                    color: #000;
                                    border: none;
                                }
                                &:hover {
                                    color: #000;
                                }
                            }
                        }
                    }
                    .right .sub .input-msg,
                    .right .sub .success-msg,
                    .right .sub .emails-protocol {
                        color: #fff;
                    }
                    .right .sub .input-email {
                        color: #fff;
                        border: 1px solid #fff !important;
                    }
                    .right .sub .form-submit {
                        background-color: #fff;
                        color: var(--navbg);
                    }
                }
                .footer-c {
                    border-top: 1px solid #343434;
                    .language-outbox {
                        .lang_text {
                            color: #919191;
                        }
                    }
                }
            }
        }
    }
    &:global(.black),
    &:global(.gray) {
        :global {
            @include devices(pad) {
                background-color: #1f1f1f;
                border-top: 1px solid #343434;
                --svg-efearth-color: #ddd;
                .footer-a {
                    h3 {
                        color: #fff;
                        border-color: #222;
                        &::before {
                            background-color: #fff;
                        }
                        &:after {
                            font-size: 20px;
                            background-color: #fff;
                        }
                    }
                    .fa-item {
                        border-color: #343434;
                    }
                    .fa-list {
                        a {
                            color: #fff;
                        }
                    }
                    .fa-itemb {
                        border-color: #343434;
                        .fa-itemb-title {
                            .lang_text {
                                color: #fff;
                            }
                            .fa-itemb-icon {
                                color: #fff !important;
                            }
                        }
                    }
                }
                .footer-b {
                    .right .sub .emails-protocol {
                        color: #fff;
                    }
                    .right .sub .input-email {
                        color: #ddd;
                        border: 1px solid #fff;
                        background-color: transparent;
                        font-size: 14px;
                        line-height: 1.2;
                        &::placeholder {
                            font-size: 14px;
                            line-height: 1.2;
                            color: #949494;
                        }
                    }
                    .right .sub .form-submit {
                        background-color: #fff;
                        color: #1f1f1f;
                    }
                    .right .sub .input-email:hover {
                        border-color: #ddd;
                    }
                    .right .sub .input-email:hover + button {
                        background-color: #ddd;
                    }
                    .sms-form {
                        .sms-container {
                            height: 42px;
                            display: flex;
                            .phone-input {
                                flex: 1;
                                height: 42px;
                                border: none;
                                background: #000;
                                color: #fff;
                                &:focus {
                                    border: none;
                                    box-shadow: none;
                                }
                                &::placeholder {
                                    color: #808080;
                                }
                            }
                            .area-select {
                                width: 100px;
                                .ant-select-selector {
                                    height: 42px;
                                    border: none;
                                    background: #222;
                                    color: #fff;
                                    .ant-select-selection-item {
                                        line-height: 42px;
                                    }
                                    .ant-select-selection-placeholder {
                                        line-height: 42px;
                                    }
                                }
                                &.ant-select-focused .ant-select-selector {
                                    box-shadow: 0 0 0 0px;
                                }
                                .ant-select-selection-search-input {
                                    height: 42px;
                                    line-height: 42px;
                                }
                            }
                            .sms-btn {
                                width: 48px;
                                height: 42px;
                                border-radius: 0;
                                border: none;
                                line-height: 46px;
                                background: #fff;
                                &:active {
                                    color: #000;
                                    border: none;
                                }
                                &:hover {
                                    color: #000;
                                }
                            }
                        }
                    }
                }
            }
            .footer-a {
                .fa-item {
                    .fa-list {
                        > a {
                            color: #afafaf !important;
                            &:hover,
                            &:active {
                                color: #fff !important;
                            }
                        }
                    }
                }
            }
            .footer-b {
                .icon-list {
                    .icon {
                        color: #e6e6e6;
                        &:hover,
                        &:active {
                            color: #fff;
                        }
                    }
                }
            }
            .footer-c {
                .footer-c-in {
                    &::before {
                        background-color: #343434;
                    }
                    .language-outbox {
                        .lang_item_current {
                            .lang_text {
                                color: #919191;
                            }
                        }
                    }
                    .left {
                        font-family: var(--ff-woff2-M);
                    }
                }
            }
        }
    }
}

.protocol-modal-sms {
    :global {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        color: rgba(0, 0, 0, 0.8);
        font-family: var(--ff-woff2-M);
        font-size: 18px;
        line-height: 24px;
        .link {
            text-decoration: underline;
        }
        .confirm-btn {
            width: 260px;
            height: 49px;
            border-radius: 45px;
            margin-top: 27px;
            margin-bottom: 20px;
            background: #3f68e0;
            font-size: 20px;
            font-family: var(--ff-woff2-M);
        }
    }
}
