
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.ef-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    transition: none;
}
.ef-link:hover {
    color: inherit;
}
.ef-link:not([href]) {
    cursor: default;
}
