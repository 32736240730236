
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    

.HeaderUser {
    position: relative;
}

.HeaderV2-r-UserNone-link {
    display: flex;
    align-items: center;
    cursor: pointer;
}