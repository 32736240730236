@import 'eco-design-micro/css/devices.scss';
@import './css/pc.scss';
@import './css/h5.scss';
// 布局
.HeaderV2-NavType1 {
    --HeaderV2-NavType1-w-l: 282px;
    display: flex;
    justify-content: center;
    gap: 60px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    
    .HeaderV2-NavType1-l {
        width: var(--HeaderV2-NavType1-w-l);
        min-width: var(--HeaderV2-NavType1-w-l);
    }
    .HeaderV2-NavType1-r {
        flex: 1;
    }
    .eco-img-item {
        background-size: contain;
    }
    @include devices(desktop) {
        padding: 0 120px;
        max-width: 100%;
        --HeaderV2-NavType1-w-l: 264px;
    }
    @include devices(pad) {
        --HeaderV2-NavType1-w-l: 100%;
        max-width: 100%;
        margin: 0 auto;
        flex-direction: column;
        padding: 0;
        .HeaderV2-NavType1-r {
            display: none !important;
        }
    }
    @include devices(h5) {
    }
}

// 1级
.HeaderV2-NavType1-item1-title {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-family: var(--ff-woff2-B);
    font-size: 14px;
    color: #000;
    cursor: pointer;
    @include devices(pad) {
        height: 50px;
        padding: var(--HeaderV2-sub-child-padding1);
        font-family: var(--ff-woff2-M);
        font-size: 16px;
    }
}

//打开1级

.HeaderV2-NavType1-item1-open {
    .HeaderV2-NavType1-item1-title .HeaderV2-nav-item-icon {
        transform: rotate(180deg);
    }
    .HeaderV2-NavType1-sub1 {
        display: flex;
        flex-direction: column;
    }
    .HeaderV2-NavSceneA-h5 {
        background-color: var(--HeaderV2-color-gray);
    }
    .HeaderV2-NavSceneA-h5 .HeaderV2-NavSceneA-bottom-border:last-child {
        display: none;
    }
}

// 2级
.HeaderV2-NavType1-sub1 {
    display: none;
    padding-left: 12px;
    @include devices(pad) {
        padding-left: 0;
    }
}
.HeaderV2-NavType1-sub1-title {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    cursor: pointer;
    font-family: var(--ff-woff2-M);
    font-size: 14px;
    .HeaderV2-nav-item-icon {
        display: none;
    }
    @include devices(pad) {
        height: 50px;
        padding: var(--HeaderV2-sub-child-padding2);
        .HeaderV2-nav-item-icon {
            display: block;
        }
    }

}

.HeaderV2-NavType1-sub1-item-open {
    .HeaderV2-NavType1-sub1-title {
        background-color: var(--HeaderV2-color-gray);
    }
    @include devices(pad) {
        .HeaderV2-NavType1-sub1-title {
            background-color: transparent;
        }
        .HeaderV2-NavType1-sub2-h5 {
            display: flex;
            flex-direction: column;
        }
    }
}

// 3级
.HeaderV2-NavType1-sub2-h5 {
    display: none;
}

// 左侧没有2级
.HeaderV2-NavType1-item1.HeaderV2-NavType1-item1-none-second {
    .HeaderV2-nav-item-icon {
        display: none;
    }
    @include devices(pad) {
        .HeaderV2-nav-item-icon {
            display: flex;
        }
    }
    &.HeaderV2-NavType1-item1-open {
        .HeaderV2-NavType1-item1-title {
            background-color: var(--HeaderV2-color-gray);
        }
        @include devices(pad) {
            .HeaderV2-NavType1-item1-title {
                background-color: transparent;
            }
            .HeaderV2-NavSceneA-h5 {
                display: flex;
            }
        }
        
    }
}