
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.efSelect {
    :global {
        position: relative;
        .ef-select-selector {
            background-color: transparent;
            border-radius: 6px;
            border: 1px solid #666;
            padding: 10px 22px 10px 29px;
            height: 36px;
            line-height: 16px;
            color: #fff;
            cursor: pointer;
            html[site='sa'] & {
                padding: 10px 29px 10px 22px;
            }
        }
        .ef-select-list {
            position: absolute;
            top: 36px;
            left: 0;
            width: 100%;
            background-color: rgb(87, 87, 87, 1);
            display: none;
            border-radius: 6px;
        }
        .ef-select-list-item {
            height: 36px;
            padding: 10px 0px 10px 29px;
            color: rgb(222, 222, 222);
            font-family: var(--font-family-Medium);
            font-size: 13px;
            line-height: 16px;
            cursor: pointer;
            html[site='sa'] & {
                padding: 10px 29px 10px 0;
            }
        }
        .ef-select-icon {
            position: absolute;
            top: 50%;
            right: 22px;
            margin-top: -7px;
            color: #999;
            transition: transform 0.5s;
            html[site='sa'] & {
                left: 22px;
                right: auto;
            }
        }
    }
    &:global(.ef-select-open) {
        :global {
            .ef-select-list {
                display: block;
            }
            .ef-select-icon {
                transform: rotate(180deg);
            }
        }
    }
    @include maxWidth('lg') {
        :global {
            .ef-select-selector {
                padding-right: 0.12rem;
                font-size: 0.12rem;
            }
            .ef-select-icon {
                right: 0.1rem;
            }
        }
    }
}
