
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import 'lowcode/common/css/devices.scss';

.CompImgTextCountdown {
    :global {
        outline: none;
        .glo-bar-title-h5,
        .glo-bar-desc-h5 {
            display: none;
        }
        .glo-bar-text-box-l {
            .glo-bar-title {
                font-family: var(--ff-woff2-B);
                font-size: 14px;
                color: var(--countdown-color-title);
                -webkit-line-clamp: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
            }
            .glo-bar-desc {
                font-family: var(--ff-woff2-M);
                font-size: 12px;
                color: var(--countdown-color-desc);
                -webkit-line-clamp: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
            }
        }
        .glo-bar-countdown {
            display: flex;
            align-items: center;
            text-align: center;
            gap: 4px;
            text-align: center;
            color: #fff;
            font-size: 14px;
        }
        .glo-bar-countdown-item-start {
            color: var(--countdown-color-label);
        }
        .glo-bar-countdown-item1 {
            width: 24px;
            height: 22px;
            line-height: 22px;
            border-radius: 4px;
            background-color: #fff;
            color: #000;
            font-weight: bold;
        }
        .glo-bar-countdown-item2:empty {
            display: none;
        }
        .glo-bar-text-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .glo-bar-text-box-l {
            text-align: left;
            max-width: 340px;
            // &.pc {
            //     display: inline-block;
            // }
            // &.mobile {
            //     display: none;
            // }
        }
        .glo-bar-text-box-r-down {
            display: flex;
        }
        .glo-bar-countdown-theme-black {
            color: #000;
            .glo-bar-countdown-item1 {
                color: #fff;
                background-color: #000;
            }
        }
        @include devices(only1920) {
            .glo-bar-text-box-pc-center {
                .glo-bar-text-box-r-down {
                    padding-right: 140px;
                }
            }
        }
        @include devices(only1366) {
            .glo-bar-text-box-pc-center {
                .glo-bar-text-box-r-down {
                    padding-right: 140px;
                }
            }
        }
        @include devices(pad) {
            height: 80px !important;

            .glo-bar-title-h5,
            .glo-bar-desc-h5 {
                display: -webkit-box;
            }
            .glo-bar-title-pc,
            .glo-bar-desc-pc {
                display: none;
            }
            .glo-bar-text-box {
                display: flex;
                flex-direction: column;
                gap: 2px;
            }
            .glo-bar-text-box-l .glo-bar-desc {
                -webkit-line-clamp: 1;
            }
            .glo-bar-text-box-l {
                display: flex;
                flex-direction: column;
                gap: 2px;
                // &.pc {
                //     display: none;
                // }
                // &.mobile {
                //     display: inline-block;
                // }
            }
            .glo-bar-text-box-r-down {
                margin-left: 0;
                justify-content: flex-start;
            }
            .glo-bar-text-box.glo-bar-text-box-h5-left {
                align-items: flex-start;
                max-width: 250px;
                margin-left: 24px;
                .glo-bar-text-box-l {
                    text-align: left;
                    width: 100%;
                }
                .glo-bar-text-box-r-down {
                    justify-content: flex-start;
                    width: 100%;
                    line-height: 22px;
                }
                .glo-bar-title-h5,
                .glo-bar-desc-h5 {
                    max-width: 250px;
                }
            }
            .glo-bar-text-box-h5-center {
                .glo-bar-text-box-l {
                    text-align: center;
                }
                .glo-bar-text-box-r-down {
                    justify-content: center;
                }
            }
            .glo-bar-countdown {
                font-size: 12px;
            }
        }
        @include devices(h5) {
        }
    }
    &:global(.glo-bar-only-bgimg) {
        @include devices(pad) {
        }
        @include devices(h5) {
        }
    }
    &:global(.glo-bar-only-bgcolor) {
        @include devices(pad) {
        }
        @include devices(h5) {
        }
    }
}
