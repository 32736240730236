
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
// 购物车图标
.ef-shoping-cart-icon {
    position: fixed;
    display: flex;
    bottom: 252px;
    right: 20px;
    left: auto;
    z-index: 2110;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: hsla(0, 0%, 100%, .96);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .1);
    border-radius: 50%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    align-items: center;
    justify-content: center;
}
// 购物车图标-数量
.ef-shoping-cart-icon-inventory {
    background-color: #f8200c;
    width: 22px;
    height: 22px;
    text-align: center;
    padding: 2px 0;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -6px;
    transform: translateX(50%);
    display: flex;
    justify-content: center;
    color: #fff;
}
// 关闭按钮
.ef-shoping-cart-close {
    position: absolute;
    top: 50%;
    right: 12px;
    font-size: 16px;
    margin-top: -8px;
    cursor: pointer;
}
// 购物车左侧弹窗
.ef-shoping-cart-win {
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    z-index: 2120;
    width: 360px;
    height: 100vh;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    max-height: 100%;
    flex-direction: column;
    box-shadow: -5px 0 5px rgba(0, 0, 0, .1);
}
// 购物车左侧弹窗-头部
.ef-shoping-cart-header {
    position: relative;
    font-family: var(--ff-woff2-B);
    padding: 18px 0 12px;
    margin: 0 24px;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #ededed;
}
.ef-shoping-cart-mainer {
    max-height: calc(100vh - 180px);
    overflow: auto;
    padding: 0 16px 16px;
    &.COUPONS{
        max-height: calc(100vh - 246px);
    }
    &.GIFT_CARD{
        max-height: calc(100vh - 354px);
    }
    &::-webkit-scrollbar{
        display: none;
    }
    .ef-shoping-cart-item:first-child {
        border-top: none;
    }
}
.ef-shoping-cart-item {
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: 8px;
    padding: 15px 0;
    border-top: 1px solid #ededed;
}
.ef-shoping-cart-item-img {
    position: relative;
    height: 60px;
}
.ef-shoping-cart-item-title {
    font-family: var(--ff-woff2-B);
    font-size: 13px;
    line-height: 17.5px;
    letter-spacing: 0;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.ef-shoping-cart-item-price {
    font-family: var(--ff-woff2-M);
    font-size: 16px;
    margin-top: 4px;
}
.ef-shoping-cart-item-price:empty {
    display: none;
}
.ef-shoping-cart-item-tools {
    margin-top: 8px;
}
// 购物车左侧弹窗-打开弹窗
.ef-shoping-cart-open {
    .ef-shoping-cart-win {
        display: flex;
    }
}
.coupon-countdown{
    padding: 6px 0;
    border-top: 1px solid #F1D0A5;
    font-family: var(--ff-woff2-M);
    font-size: 12px;
    line-height: normal;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    .countdown-time{
        display: flex;
        align-items: center;
        gap: 16px;
        .date-list{
            font-family: var(--ff-woff2-B);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background-color: #FFE6B0;
            width: 24px;
            height: 24px;
            position: relative;
            &:after{
                content: ':';
                position: absolute;
                top: 50%;
                right: -10px;
                transform: translateY(-50%);
                font-family: var(--ff-woff2-M);
                font-size: 14px;
                line-height: 1;
            }
            &:last-child{
                &::after{
                    display: none;
                }
            }
        }
    }
}
.cart-coupon{
    margin-top: 12px;
    padding: 0 8px;
    border-radius: 2px;
    background-color: #FFF4DB;
    color: #A06314;
    .coupon-info{
        padding: 6px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        .coupon-code{
            width: calc(50% - 8px);
            display: flex;
            align-items: center;
            gap: 4px;
            font-family: var(--ff-woff2-M);
            font-size: 11px;
            line-height: normal;
            cursor: pointer;
        }
        .coupon-discount{
            font-family: var(--ff-woff2-B);
            font-size: 12px;
            width: 50%;
            text-align: right;
        }
    }
}
// 左侧弹窗-脚部
.ef-shoping-cart-footer {
    margin-top: auto;
    width: 100%;
    padding: 20px;
    box-shadow: 0 -5px 8px 0 hsla(0, 0%, 92%, .25);
    text-align: center;
    background-color: #fff;
}
.ef-shoping-cart-footer-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--ff-woff2-B);
    font-size: 16px;
    line-height: normal;
}
.subtotal-price, .discount-price{
    margin-bottom: 14px;
    font-family: var(--ff-woff2-M);
    font-size: 14px;
    line-height: normal;
}
.gift-card{
    padding: 0 8px;
    border-radius: 2px;
    background-color: #D8ECFF;
    line-height: normal;
    margin-bottom: 14px;
    .gift-info{
        display: flex;
        gap: 0 8px;
        align-items: center;
        justify-content: space-between;
        .gift-code{
            padding: 6px 0;
            display: flex;
            gap: 8px;
            align-items: center;
            font-family: var(--ff-woff2-M);
            font-size: 12px;
            cursor: pointer;
        }
        .gift-discount{
            font-family: var(--ff-woff2-B);
            font-size: 12px;
        }
    }
    .coupon-countdown{
        border-top-color: #7190AE;
        .countdown-time{
            .date-list{
                background-color: #fff;
            }
        }
    }
}
.ef-shoping-cart-footer-checkout {
    margin-top: 15px;
    display: inline-block;
    padding: 10px 24px;
    background-color: #2164ff;
    color: #fff;
    border-radius: 42px;
    width: 100%;
    font-family: var(--ff-woff2-B);
    font-size: 17px;
    line-height: 23px;
    cursor: pointer;
}