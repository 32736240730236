@import 'eco-design-micro/css/devices.scss';

.HeaderV2-NavType4 {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px 0 32px 0;
    gap: 10px;
    @include devices(desktop) {
        margin-left: 120px;
        margin-right: 120px;
    }
    @include devices(pad) {
        overflow-x: auto;
        justify-content: flex-start;
        margin: 0;
        padding: 40px 48px;
        background-color: #f4f4f4;
    }
    @include devices(h5) {
        padding: 40px 24px;
        background-color: #f4f4f4;
    }
}
.HeaderV2-NavType4::-webkit-scrollbar {
    display: none;
}
.HeaderV2-NavType4-item {
    display: flex;
    width: 308px;
    height: 180px;
    position: relative;
    @include devices(pad) {
        flex: none;
    }
}

.HeaderV2-NavType4-item-img {
    border-radius: 4px;
    overflow: hidden;
}
.HeaderV2-NavType4-item-msg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.HeaderV2-NavType4-item-title {
    font-family: var(--eco-design-font-B);
    font-size: 14px;
    color: #fff;
}
.HeaderV2-NavType4-item-desc {
    font-family: var(--eco-design-font-M);
    font-size: 12px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}