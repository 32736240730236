
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import '@elem/styles/devices.scss';

.Header-regionPopup {
    position: absolute;
    top: 51px;
    z-index: 25;
    width: 100%;
    background-color: #444;
    display: flex;
    justify-content: center;
    .content {
        max-width: 1200px;
        height: 100%;
        margin-left: 128px;
        margin-right: 128px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .left {
            padding: 7px 30px 7px 0;
            font-family: var(--ff-woff2-M);
            font-size: 13px;
            line-height: 19px;
            color: #dedede;
            max-width: 500px;
            min-height: 52px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .right {
            display: flex;
            justify-content: flex-end;
            padding-right: 40px;

            .selector {
                width: 320px;
                position: relative;

                .checkIcon {
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    z-index: 10;
                    transform: translateY(-50%);
                    font-size: 12px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    html[site='sa'] & {
                        left: auto;
                        right: 10px;
                    }
                }

                .empty {
                    position: absolute;
                    top: 0;
                    left: 0px;
                    z-index: 10000000001;
                    height: 72px;
                    width: calc(100% + 0px);
                    pointer-events: none;
                    border: 2px solid #666;
                    border-radius: 6px;
                    outline: 0;
                    overflow: hidden;
                }
            }

            .btn {
                margin-left: 12px;
                padding: 7px 10px;
                border-radius: 6px;
                background: #f0f0f0;
                font-family: var(--ff-woff2-M);
                font-size: 13px;
                line-height: 22px;
                color: #03060b;
                cursor: pointer;

                &:hover {
                    background: #fff;
                }
                html[site='sa'] & {
                    margin-left: 0;
                    margin-right: 12px;
                }
            }
        }

        .close {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 30px;
            font-family: var(--ff-woff2-M);
            width: 20px;
            height: 20px;
            color: #999;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            html[site='sa'] & {
                left: -50px;
                right: auto;
            }
        }
    }
    @include devices(pad) {
        height: auto;
        .content {
            display: block;
            width: 100%;
            margin: 0;
            padding: 24px 24px 38px !important;

            .close {
                top: 10px;
                right: 10px;
                width: 20px;
                height: 20px;
                font-size: 34px;
                transform: translateY(0);
                html[site='sa'] & {
                    left: 15px;
                    right: auto;
                }
            }

            .left {
                width: auto;
                max-width: unset;
                padding: 0;
                font-family: var(--ff-woff2-M);
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0;
                word-spacing: normal;
            }

            .right {
                margin-top: 10px;
                justify-content: space-between;
                width: 100%;
                padding-right: 0;
                .btn {
                    min-width: 60px;
                    text-align: center;
                }
                .selector {
                    width: 250px;
                }
                .empty {
                    height: 72px;
                }
            }
        }
    }
}

body:has(.glo-bar-top) {
    .Header-regionPopup {
        top: 101px;
    }
}
body:has(.glo-bar-top .glo-bar-type-img-text-countdown) {
    .Header-regionPopup {
        top: 101px;
    }
    @include devices(pad) {
        .Header-regionPopup {
            top: 132px;
        }
    }
}

body:has(.ecoflow-frame-header .ecoflow-header) {
    .Header-regionPopup {
        top: 49px;
    }
    @include devices(pad) {
        .Header-regionPopup {
            top: 49px;
        }
    }
}