@import 'eco-design-micro/css/devices.scss';
@import './css/h5.scss';
@import './css/pc.scss';
@import './css/length.scss';

.HeaderV2-NavSceneA {
    display: none;
    flex-direction: column;
    gap: 24px;
    .HeaderV2-NavSceneA-icon {
        display: flex;
        width: 13px;
        height: 13px;
        background-image: url('https://websiteoss.ecoflow.com/upload/image/1742369814692/arr01.svg');
        background-size: 100% 100%;
    }
    @include devices(pad) {
        gap: 0;
    }
}

.HeaderV2-NavSceneA-item1 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include devices(pad) {
        gap: 0;
    }
}
.HeaderV2-NavSceneA-item1:has(.HeaderV2-NavSceneA-item1-h .HeaderV2-NavSceneA-item1-h-btn2) {
    @include devices(pad) {
        position: relative;
        .HeaderV2-NavSceneA-item1-h {
        }
        .HeaderV2-NavSceneA-item1-h-btn1 {
            height: 50px;
            display: flex;
            align-items: center;
        }
        .HeaderV2-NavSceneA-item1-h-btn2 {
            position: absolute;
            left: 0;
            bottom: 14.5px;
            z-index: 9;
            margin: var(--HeaderV2-sub-child-padding2);
            color: #000;
        }
        .HeaderV2-NavSceneA-bottom-border {
            display: block;
            height: 49px;
        }
    }
}
.HeaderV2-NavSceneA-item2 {
    position: relative;
    border-radius: 6px;
    order: 5;
    background-color: var(--HeaderV2-color-gray);
}

.HeaderV2-NavSceneA-item2-label {
    font-family: var(--ff-woff2-B);
    font-size: 12px;
    color: #3F68E0;
    margin-bottom: 2px;
    @include devices(pad) {
        &:empty {
            display: none;
        }
    }
}

.HeaderV2-NavSceneA-item2-title {
    font-family: var(--ff-woff2-B);
    font-size: 14px;
    line-height: 1.2;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.HeaderV2-NavSceneA-item2-desc {
    font-family: var(--ff-woff2-M);
    font-size: 12px;
    color: #666;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    white-space: pre-line;
    @include devices(pad) {
        &:empty {
            display: none;
        }
    }
}

// 头部
.HeaderV2-NavSceneA-item1-h {
    display: flex;
    align-items: center;
    gap: 24px;
    .HeaderV2-NavSceneA-item1-h-btn1 {
        font-family: var(--ff-woff2-B);
        font-size: 14px;
        color: #000;
    }
    .HeaderV2-NavSceneA-item1-h-btn1:empty {
        display: none;
    }
    .HeaderV2-NavSceneA-item1-h-btn2 {
        font-family: var(--ff-woff2-M);
        font-size: 14px;
        color: #696C71;
        display: flex;
        align-items: center;
        gap: 4px;
    }
    .HeaderV2-NavSceneA-item1-h-btn2:hover {
        text-decoration: underline;
    }
    .HeaderV2-NavSceneA-item1-h-onlyShowH5Btn {
        display: none;
    }
    @include devices(pad) {
        .HeaderV2-NavSceneA-item1-h-btn1 {
            display: flex;
            align-items: center;
            height: 49px;
        }
        .HeaderV2-NavSceneA-item1-h-onlyShowH5Btn {
            display: flex;
        }
    }
}
.HeaderV2-NavSceneA-item1-h:empty {
    display: none;
}


// 配件
.HeaderV2-NavSceneA-item1-c {
    display: grid;
    grid-template-columns: calc(20% - 8px) calc(20% - 8px) calc(20% - 8px) calc(20% - 8px) calc(20% - 8px);
    gap: 10px;
}

// 其它
.HeaderV2-NavSceneA-item1-f {
    display: flex;
    flex-wrap: wrap;
    line-height: 16px;
    padding: 16px 0 0 0;
    gap: 24px;
    align-items: center;
    border-top: 1px solid #D9D9D9;
    .HeaderV2-NavSceneA-item1-f-item {
        display: flex;
        gap: 4px;
        align-items: center;
        font-family: var(--ff-woff2-M);
        font-size: 14px;
        color: #696C71;
        line-height: 17px;
    }
    .HeaderV2-NavSceneA-item1-f-item:hover {
        text-decoration: underline;
    }
    @include devices(pad) {
        margin: var(--HeaderV2-sub-child-padding2);
        border-top: none;
        padding-bottom: 16px;
        .HeaderV2-NavSceneA-item1-f-item {
            color: #000;
        }
    }
}
.HeaderV2-NavSceneA-item1-f:empty {
    display: none;
}
.HeaderV2-NavSceneA-bottom-border {
    display: none;
    @include devices(pad) {
        display: block;
        padding: 0;
        height: 13px;
        border-bottom: 1px solid #D9D9D9;
        margin: var(--HeaderV2-sub-child-padding2);
    }
}

// 更多卡片
.HeaderV2-CardMoreA {
    order: 9 !important;
    display: flex !important;
    gap: 24px;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    padding: 45px 0;
    .HeaderV2-CardMoreA-icon {
        width: 40px;
        height: 40px;
    }
    .HeaderV2-CardMoreA-text {
        font-family: var(--ff-woff2-M);
        color: #696C71;
        font-size: 14px;
    }
    @include devices(pad) {
        display: none !important;
    }
}

// label 颜色
.HeaderV2-NavSceneA-item2-label-New {
    .HeaderV2-NavSceneA-item2-label {
        color: #3F68E0;
    }
}
.HeaderV2-NavSceneA-item2-label-Hot {
    .HeaderV2-NavSceneA-item2-label {
        color: #F8200C;
    }
}
.HeaderV2-NavSceneA-item2-label-Sale {
    .HeaderV2-NavSceneA-item2-label {
        color: #f7a40a;
    }
}