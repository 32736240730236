
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
// 顶部bar条
.bar {
    :global {
        .glo-bar-item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            color: #fff;
            font-size: 14px;
            font-family: var(--ff-woff2-M);
            background-color: #3F68E0;
            text-align: center;
        }
        .glo-bar-text-box {
            position: relative;
            z-index: 2;
            width: 100%;
            max-width: 760px;
            margin: 0 auto;
        }
        .glo-bar-text-box:has(.glo-bar-text-box-r) {
            display: flex;
            justify-content: center;
            align-items: center;
            .glo-bar-text-box-r {
                display: flex;
                align-items: center;
                margin-left: 8px;
            }
            .glo-bar-btn {
                max-width: 100px;
                padding: 0 8px;
                line-height: 25px;
                border-radius: 4px;
                background-color: #fff;
                color: #000;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                font-weight: bold;
            }
        }
        .editor-glo-bar-close{
            position: absolute;
            top: 12px;
            cursor: pointer;
        }
        .glo-bar-title {
            line-height: 17px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            font-weight: bold;
        }
        .glo-bar-desc {
            font-size: 12px;
            line-height: 14px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .glo-bar-link-desc {
            margin-left: 8px;
            text-decoration: underline;
        }
        .glo-bar-button {
            margin-left: 12px;
            white-space: nowrap;
            height: 25px;
            line-height: 25px;
            border-radius: 4px;
            padding: 0 8px;
            background-color: #fff;
            color: #000;
        }
        
        .swiper .swiper-button-prev, .swiper .swiper-button-next {
            width: 24px;
            height: 24px;
            margin-top: -12px;
        }

        .glo-bar-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        
        @media only screen and (width > 1024px) {
            .glo-bar-padding {
                padding: 0 calc(10% + 24px);
            }
            .swiper .swiper-button-prev {
                left: 5%;
            }
            .swiper .swiper-button-next, .editor-glo-bar-close {
                right: 5%;
            }
        }
        @media only screen and (width <= 1024px) {
            .glo-bar-item {
                height: 50px;
            }
            .glo-bar-text-box {
                max-width: 327px;
            }
            .swiper .swiper-button-prev {
                left: 2%;
            }
            .swiper .swiper-button-next, .editor-glo-bar-close {
                right: 2%;
            }
        }
        @media only screen and (width < 400px) {
            .swiper .swiper-button-prev {
                left: 0;
            }
            .swiper .swiper-button-next, .editor-glo-bar-close {
                right: 0;
            }
        }
    }
}