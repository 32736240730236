@import 'eco-design-micro/css/devices.scss';

.HeaderV2-NavType3 {
    display: flex;
    justify-content: center;
    gap: 90px;
    @include devices(pad) {
        flex-direction: column;
        background-color: #f4f4f4;
        padding: 0 48px;
        gap: 12px;
    }
    @include devices(h5) {
        padding: 0 24px;
    }
}
.HeaderV2-NavType3-item {
    @include devices(pad) {
        padding-bottom: 22px;
        border-bottom: 1px solid #d9d9d9;
    }
}
.HeaderV2-NavType3 .HeaderV2-NavType3-item-title {
    display: flex;
    align-items: center;
    height: 37px;
    font-family: var(--eco-design-font-M);
    font-size: 14px;
    color: #757575;
    line-height: 1.2;
    @include devices(pad) {
        height: 44px;
    }
}

.HeaderV2-NavType3-item-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    @include devices(pad) {
        gap: 0;
        margin-top: 0;
    }
}

.HeaderV2-NavType3 .HeaderV2-NavType3-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.HeaderV2-NavType3 .HeaderV2-NavType3-item-list-link {
    display: flex;
    align-items: center;
    font-family: var(--ff-woff2-M);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: #000;
    &:hover {
        text-decoration: underline;
        font-weight: 500;
    }
    @include devices(pad) {
        padding: 15px 0;
    }
}