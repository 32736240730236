
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import 'lowcode/common/css/devices.scss';

.ef-check-in-CheckInCountdown {
    position: fixed;
    right: 24px;
    bottom: 320px;
    z-index: 9999;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3F68E0;
    padding: 1px;
    font-family: var(--ff-woff2-B);
    font-size: 18px;
    border-radius: 50%;
    overflow: hidden;
}
