@import 'eco-design-micro/css/devices.scss';

.HeaderV2-NavSceneA {
    .HeaderV2-NavSceneA-item1.HeaderV2-NavSceneA-item1-scene-n.HeaderV2-NavSceneA-item1-length-2 .HeaderV2-NavSceneA-item1-c {
        grid-template-columns: 1fr 1fr;
    }
    .HeaderV2-NavSceneA-item1.HeaderV2-NavSceneA-item1-length-3 .HeaderV2-NavSceneA-item1-c {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @include devices(pad) {
    }
    @include devices(h5) {
    }
}